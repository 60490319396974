import React, { useState } from "react";
import "./style.css";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { register } from "../../../API/Auth";
import errorMessage from "../../../Utils/Helper/errorMessage";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const onFinish = async (values) => {
    setLoading(true);
    const signupData = await register(values);
    if (Math.floor(signupData?.status / 100) === 2) {
      navigate("/verification");
    } else {
      notification.error({
        message: "Unable to Signup",
        description: errorMessage(signupData?.response?.data),
      });
    }
    setLoading(false);
  };

  return (
    <div className="signup">
      <div className="signup-card">
        <div className="title">
          <h1>Signup</h1>
          <p>Welcome to DNSai</p>
        </div>
        <div className="form">
          <Form
            name="normal_signup"
            className="signup-form"
            onFinish={onFinish}
            requiredMark={false}
            initialValues={{
              remember: true,
            }}
            {...formLayout}
          >
            <Form.Item
              label={<span className="email-label">Email</span>}
              name="email"
              rules={[
                {
                  message: "Please input your Email!",
                  required: true,
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              label={<span className="name-label">Name</span>}
              name="name"
              rules={[
                {
                  message: "Please input your Name!",
                  required: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              label={<span className="password-label">Password</span>}
              name="password"
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                required={true}
                iconRender={(visible) =>
                  visible ? (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>
            <Form.Item
              label={<span className="password-label">Confirm Password</span>}
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  message: "Please re-enter the Password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                required={true}
                iconRender={(visible) =>
                  visible ? (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="signup-form-button"
                disabled={loading}
                loading={loading}
              >
                Sign Up
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <span className="login-link">Already have an account? </span>
              <span onClick={goToLogin} className="login-link underline">
                Login
              </span>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
