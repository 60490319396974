import React, { useContext, useState } from "react";
import "./style.css";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { login } from "../../../API/Auth";
import { encryptText } from "../../../Utils/Encryption";
import updateUserStates from "../../../Utils/UpdateUsersState";
import { UserContext } from "../../../Utils/Context/userContext";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setUserData, setUserToken } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const loginData = await login(values);
    if (Math.floor(loginData?.status / 100) === 2) {
      localStorage.clear();
      localStorage.setItem(
        encryptText("token"),
        encryptText(
          JSON.stringify({
            access: loginData?.data?.access,
            refresh: loginData?.data?.refresh,
          })
        )
      );
      localStorage.setItem(
        encryptText("user"),
        encryptText(
          JSON.stringify({
            id: loginData?.data?.id,
            username: loginData?.data?.username,
            role: loginData?.data?.role,
            name: loginData?.data?.name,
            fa: loginData?.data?.fa,
            email: loginData?.data?.email,
            verified: loginData?.data?.verified,
            profile_photo: loginData?.data?.photo
              ? process.env.REACT_APP_BACKEND_PHOTO_URL + loginData?.data?.photo
              : null,
            settings: false,
          })
        )
      );
      updateUserStates(setUserData, setUserToken);
      messageApi.open({
        type: "success",
        content: "Login Successful",
      });
    } else {
      notification.error({
        message: "Invalid login details",
        description: loginData?.response?.data?.detail,
      });
    }
    setLoading(false);
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      {contextHolder}
      <div className="login">
        <div className="login-card">
          <div className="title">
            <h1>Login</h1>
            <p>Welcome to DNSai</p>
          </div>
          <div className="form">
            <Form
              name="normal_login"
              className="login-form"
              requiredMark={false}
              onFinish={onFinish}
              {...formLayout}
            >
              <Form.Item
                label={<span className="username-label">Email</span>}
                name="email"
                rules={[
                  {
                    message: "Please input your Email!",
                    required: true,
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item
                label={<span className="password-label">Password</span>}
                name="password"
                rules={[
                  {
                    message: "Please input your Password!",
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeInvisibleOutlined
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <EyeOutlined onClick={togglePasswordVisibility} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                <span
                  onClick={goToForgotPassword}
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "22px",
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                    paddingInlineStart: "10px",
                  }}
                >
                  Forgot Password?
                </span>
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="unchecked" noStyle>
                  <Checkbox className="remember-me">Remember Me</Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={loading}
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <span className="signup-link">Don't have an account? </span>
                <span onClick={goToSignup} className="signup-link underline">
                  Sign Up
                </span>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
