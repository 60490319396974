import React, { useContext, useState } from "react";
import "./style.css";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../../API/Auth";

const PasswordReset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const res = await resetPassword(values, params?.uuid);
    if (Math.floor(res?.status / 100) === 2) {
      message.success("Password Reset Successfull!");
      navigate("/login");
    } else {
      message.error("Password Reset Failed!");
    }
    setLoading(false);
  };

  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="login">
      <div className="login-card">
        <div className="title">
          <h1>Password Reset</h1>
          <p>Welcome to DNSai</p>
        </div>
        <div className="form">
          <Form
            name="normal_login"
            className="login-form"
            requiredMark={false}
            onFinish={onFinish}
            {...formLayout}
          >
            <Form.Item
              label={<span className="password-label">Password</span>}
              name="password"
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                required={true}
                iconRender={(visible) =>
                  visible ? (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>
            <Form.Item
              label={<span className="password-label">Confirm Password</span>}
              name="confirmpassword"
              rules={[
                {
                  required: true,
                  message: "Please re-enter the Password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                required={true}
                iconRender={(visible) =>
                  visible ? (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <span
                onClick={goToLogin}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "22px",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  paddingInlineStart: "10px",
                }}
              >
                Go to Login Instead
              </span>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loading}
                loading={loading}
              >
                Reset Password
              </Button>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <span className="signup-link">Don't have an account? </span>
              <span onClick={goToSignup} className="signup-link underline">
                Sign Up
              </span>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
